body {
  margin: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  background-color: transparent;
  border: 1px solid #858585;
  color: #858585;
  padding: 15px 32px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  margin: 8px;
  height: 46px
}

img {
  display: block
}

.MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
